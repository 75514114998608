<template>
<div class="navbar">
<div id="logo">
  <router-link to="/">
    <span style="color:var(--man1)">R.</span>
    <span style="color:var(--man3)">M.</span>
    <span style="color:var(--text)">F.</span>
    <span style="color:var(--man2)">C.</span>
  </router-link>
</div>
  <div>
    <button type="button" class="btn nav-btn" v-if="address" @click="$emit('logout')">Disconnect {{ address ? `${address.substring(0,6)}...${address.substring(address.length - 4)}` : "" }}</button>
    <button type="button" class="btn nav-btn" v-if="!address" @click="$emit('login')">Connect Wallet</button>
  </div>

</div>
</template>

<script>
export default {
  name: "Nav",
  props: ['address']
}
</script>

<style scoped>
.navbar{
  z-index: 99;
  font-size: 0.85em;
  width: 100%;
  background: linear-gradient(180deg, rgba(8,8,8,1) 0%, rgba(8,8,8,1) 85%, rgba(8,8,8,0) 100%);
  height: 80px;
  display: flex;
  line-height: 64px;
  padding: 0 12px;
  box-sizing: border-box;
  position: fixed;
  top:0;
  justify-content: space-between;
}

.nav-btn{
  background: var(--man2);
  color: var(--text);
}
.nav-btn:hover{
  background: var(--man1);
}

#logo{
  font-size: 2em;
  font-family: 'Inknut Antiqua', serif;
  font-weight: 600;
  padding-left: 8px;
}
</style>