<template>
  <div id="toastbar" ref="toastbar" />
  <audio loop ref="audio">
    <source src="/media/backing.mp3">
  </audio>
<div v-if="blurred"
     style="
      top:0;
      left:0;
      width:100%;
      height: 100%;
      z-index: 100;
      background: var(--black);
      opacity: 0.8;
      position: fixed;
      backdrop-filter: blur(2px);
    " />
  <Nav
       @login="login"
       @logout="logout"
       :address="address"
  />
  <router-view
      @login="login"
      @blur="toggleBlur"
      @play="playMusic"
      @stop="stopMusic"
      @toast="addToast"
      :address="address"
      :db="db"
  />
  <Footer />
</template>

<script>
import Nav from './components/Nav';
import Footer from "./components/Footer";
import {manStore} from "@/store";
import faunadb from 'faunadb';
import {ethers} from "ethers";
//import { Link } from '@imtbl/imx-sdk';

export default {
  name: "App",
  components: {Nav, Footer},
  data() {
    return {
      address: null,
      link: null,
      db: null,
      blurred:false
    }
  },
  methods: {
    async login() {
      /*try {
        const {address, starkPublicKey} = await this.link.setup({});
        this.address = address
        localStorage.setItem('address', address);
        localStorage.setItem('STARK_PUBLIC_KEY', starkPublicKey);
      } catch (e) {
        this.addToast( "Error connecting to wallet")
        console.error(e)
      }*/

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        try{
          await window.ethereum.request({ method: 'eth_requestAccounts' });
        }catch(e){
          this.addToast( "Error connecting to wallet")
        }

        const signer = provider.getSigner();
        let sig
        try{
          sig = await signer.signMessage("Log in to Real Man Fight Club")
        }catch(e){
          this.addToast("Error authorizing command")
        }

        const options = {method: 'POST', body: sig};
        let resp = await fetch(this.$backendhost + "/login", options)
        if (resp.status !== 200) {
          this.addToast("Error logging in.")
        } else {
          let retJson = await resp.json()
          this.address = retJson.address
          localStorage.setItem('address', retJson.address);
        }
      },
    logout() {
      manStore.logout()
      this.address = null
      localStorage.removeItem('address');
      localStorage.removeItem('STARK_PUBLIC_KEY');
      this.$router.replace("/")
    },
    toggleBlur(){
      this.blurred= !this.blurred
    },
    playMusic(){
      this.$refs.audio.volume = 0.5
      this.$refs.audio.play()
    },
    stopMusic(){
      this.$refs.audio.volume = 0.5
      this.$refs.audio.pause()
    },
    addToast(msg){
      let node = document.createElement('div')
      node.innerHTML = msg
      this.$refs.toastbar.appendChild(node)
      node.classList.add('show')
      setTimeout(() => {node.classList.replace('show', 'hide')}, 2500)
      setTimeout(() => {this.$refs.toastbar.removeChild(node)}, 2600)
    }
  },
  async mounted() {
    // TODO Error checking this.
    this.address = localStorage.getItem('address')
    //this.link = new Link('https://link.x.immutable.com')
    this.db = await new faunadb.Client({secret: "fnAEY-1ucAACTBzJDonLa6ucvMOpETQ4S3T9RRP4"})
  }
}

</script>





<style>
@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@600&family=Metamorphous&display=swap');

:root{
  --black: #080808;
  --text: #fff8db;
  --man1: #5f8c2c;
  --man2: #4d0000;
  --man3: #7f97c9;
}

html, body{
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: 'Metamorphous', cursive;
  /*background: #2a2a2a;*/
  color: var(--text);
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
}

body{
  background-image: url("/img/bg.jpg") ;
  background-attachment: fixed;
}

h1, h2, h3, h4{
  font-family: 'Inknut Antiqua', serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1.5em;
}

a{
  text-decoration: none;
  color: var(--man3);
}

.btn{
  outline: none;
  border: 0;
  border-radius: 5px;
  padding: 10px 8px;
  margin: 3px;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Metamorphous', cursive;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.Meteorite{
  color: #4d0000;
}
.Diamond{
  color: #6dfffb;
}
.Gold{
  color: #ffcb00;
}
.Shadow{
  color: #b100ff;
}

.Meteorite-bg{
  background: radial-gradient(#4d0000, #555);
}
.Diamond-bg{
  background: radial-gradient(#6dfffb, #555);
}
.Gold-bg{
  background: radial-gradient(#ffcb00, #555);
}
.Shadow-bg{
  background: radial-gradient(#b100ff, #555);
}

input, select{
  outline: none;
  border: 1px solid var(--black);
  border-radius: 5px;
  padding: 10px 8px;
  margin: 3px 3px 12px;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Metamorphous', cursive;
}

#toastbar{
  position: fixed;
  z-index: 1000000;
  width: 100%;
}

#toastbar div{
  font-weight: bold;
  text-align: center;
  width: 50%;
  min-width: 200px;
  background: #ffa600;
  border-radius: 5px;
  margin: 36px auto;
  padding: 12px 8px;
  color: var(--black);
  visibility: hidden;
}

#toastbar div.show{
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

#toastbar div.hide{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s ease-in-out;
}

@keyframes toastin {
  from { opacity: 0;}
  to { opacity: 1;}
}

.flash{
  animation: fadeIn 0.5s infinite alternate;
}

@keyframes fadeIn {
  from { opacity: 0; }
}

.toppertitle{
  margin-top: 60px;
  padding:8px;
  font-size: 48pt;
  line-height: 2em;
}
</style>


