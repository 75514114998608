import {reactive} from 'vue'

export const manStore = {

  state: reactive({
    men: {},
    checked: false,
    menArray: []
  }),

  confirmChecked(){
    this.state.checked = true
  },

  updateMan(id, newdata) {
    // eslint-disable-next-line no-prototype-builtins
    if(this.state.men.hasOwnProperty(id)){
      this.state.men[id] = {
        ...this.state.men[id],
        ...newdata
      }
    } else {
      this.state.men[id] = newdata
    }
    this.state.menArray = Object.values(this.state.men)
  },
  logout(){
    this.state.men = {}
    this.state.checked = false
  },
  sortMenArray(key){
    this.state.menArray = this.state.menArray.sort((a, b) => b[key] - a[key]);
  },
  reverseMenArray(){
    this.state.menArray = this.state.menArray.reverse()
  },
  resetMenArray(){
    this.state.menArray = Object.values(this.state.men)
  },
  filterMenArrayByQuality(targetQuality){
    this.state.menArray = this.state.menArray.filter(man => man.quality === targetQuality);
  }
}

export const fightStore = {

  state: reactive({
    fight: {}
  }),

  addFight(stamp, data) {
    this.state.fight[stamp] = data
  },
  getFight(stamp){
    return this.state.fight[stamp]
  },
  endFight(stamp){
    delete(this.state.fight[stamp])
  }

}