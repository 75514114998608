import { createRouter, createWebHashHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import ManList from "@/views/ManList";

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/men',
    name: 'Men',
    component: ManList
  },
  {
    path: '/myman/:id',
    name: 'OneMan',
    component: () => import(/* webpackChunkName: "man1" */ '../views/OneMan.vue')
  },
  {
    path: '/fight/:stamp',
    name: 'Fight',
    component: () => import(/* webpackChunkName: "man1" */ '../views/Fight.vue')
  },
  {
    path: '/man/:id',
    name: 'PublicMan',
    component: () => import(/* webpackChunkName: "man2" */ '../views/PublicMan.vue')
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import(/* webpackChunkName: "lb" */ '../views/Leaderboard.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "abt" */ '../views/About.vue')
  },
  {
    path: '/realcard',
    name: 'BuyCard',
    component: () => import(/* webpackChunkName: "rcd" */ '../views/BuyCard.vue')
  },
  {
    path: '/recruit',
    name: 'ManRaffle',
    component: () => import(/* webpackChunkName: "rfl" */ '../views/ManRaffle.vue')
  },
  {
    path: '/dungeon',
    name: 'Dungeon',
    component: () => import(/* webpackChunkName: "rfl" */ '../views/Dungeon.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
