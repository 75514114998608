import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)
app.config.globalProperties.$backendhost = "https://realmanfightclub.net" //"https://realmanfightclub.net" "http://127.0.0.1:8787"
app.config.globalProperties.$backend = app.config.globalProperties.$backendhost + "/endpoint"
app.config.globalProperties.$settings = {
  hidingCost: 10,
  hidingDaily: 2,
  injuryCost: 10,
  weaponpetMultiplier: {
    Meteorite: 1,
    Shadow: 3,
    Gold: 6,
    Diamond: 12
  }
}
app.config.globalProperties.$tokens = {
  imx: "0x26b81657e09d3a6a18ff1c6d776fd09f4bb9ee80",
  gods: "0x4c04c39fb6d2b356ae8b06c47843576e32a1963e"
}


app.use(router).mount('#app')
