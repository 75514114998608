<template>
  <div>

    <div style="margin: 0 auto 6px;border-radius: 5px;width:160px;position:relative;">
      <div v-if="man.pet" :class="man.pet.quality+'-bg'" class="petspot tooltip">&nbsp;<span class="tooltiptext">
      <img class="imgblk" :src="`https://images.godsunchained.com/art2/250/${man.pet.proto}.jpg`" :class="man.pet.quality+'-border-thin'" v-if="man.pet">
  </span></div>
      <div v-if="man.weapon" :class="man.weapon.quality+'-bg'" class="weaponspot tooltip">&nbsp;<span class="tooltiptext">
      <img class="imgblk" :src="`https://images.godsunchained.com/art2/250/${man.weapon.proto}.jpg`" :class="man.weapon.quality+'-border-thin'" v-if="man.weapon">
  </span></div>
      <img :src="`/img/${man.quality}b.png`" class="border">
      <div class="skullcont">
        <img src="/img/skull.png" class="skull" v-if="man.manpower < 1">
      </div>
      <img src="/img/profile.jpg" class="manpf" :class="{'deadimg': man.manpower < 1}">
    </div>
  </div>

</template>

<script>
export default {
  name: "ManPfp",
  props: ["man"]
}
</script>

<style scoped>
.Meteorite-border-thin{
  border: 2px solid #4d0000;
}
.Diamond-border-thin{
  border: 2px solid #6dfffb;
}
.Gold-border-thin{
  border: 2px solid #ffcb00;
}
.Shadow-border-thin{
  border: 2px solid #b100ff;
}

.manpf{
  display: block;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.deadimg {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.border{
  top:0;
  left:0;
  position: absolute;
  z-index: 75;
  display: block;
  width: 101%;
  height: 101%;
  box-sizing: border-box;
}

.skullcont{
  top:0;
  left:0;
  position: absolute;
  z-index: 50;
  display: block;
  width: 100%;
}

.skull{
  margin: 24px auto 0;
  display: block;
}

.petspot{
  position: absolute;
  bottom: 5px;
  left: 12px;
  z-index: 80;
  width: 14px;
  height: 14px;
  border-radius: 20px;
  cursor: pointer;
}

.weaponspot{
  position: absolute;
  bottom: 5px;
  right: 12px;
  z-index: 80;
  width: 14px;
  height: 14px;
  border-radius: 20px;
  cursor: pointer;
}

.tooltip {
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.imgblk{
  width: 100px;
}
</style>