<template>
  <div id="manlist">
    <h1 id="manlisttitle">Your Army</h1>
    <div id="filterbar">
      <div>
        Man Quality:
        <select v-model="qualityFilter" @change="filterByQuality">
          <option>All</option>
          <option>Meteorite</option>
          <option>Shadow</option>
          <option>Gold</option>
          <option>Diamond</option>
        </select>
      </div>
      <div>
        Sort by:
        <select v-model="sorter" @change="mansort(sorter)">
          <option value="id">ID #</option>
          <!--option value="name">Name</option-->
          <option value="manpower">Manpower</option>
          <option value="fights">Fights</option>
          <option value="wins">Wins</option>
        </select>
      </div>
      <div>
        <button type="button" class="btn" @click="reverse">Reverse order</button>
      </div>
      <div>
        <button type="button" class="btn" @click="reset">Reset</button>
      </div>
    </div>
    <div v-if="loading" class="flash" style="font-style: italic;font-size: 120%;width: 100%;text-align: center;">
      Loading your Real Man troops...
    </div>
    <div v-else-if="men.length===0">
      <div style="max-width: 1000px;margin: 0 auto 180px;text-align: center;">
        <p>There are no Real Men to show!</p>
        <p><a href="https://tokentrove.com/collection/GodsUnchainedCards?search=A%20Real%20Man" target="_blank">Click here to buy some</a>.</p>
        <p>or</p>
        <p><router-link to="/recruit">Try to recruit a man here</router-link>.</p>
      </div>
    </div>
    <div v-else>
      <div style="text-align: center; max-width: 1000px; width:100%; padding: 0 8px; margin: 6px auto;">
        <p><em>Click a Man to view his profile and send him into battle!</em></p>
        <div><small>or</small></div>
        <p><router-link to="/recruit">Try to recruit a brand new man here</router-link>.</p>
      </div>
      <div class="container">
        <div v-for="man in men" :key="man.id" class="manbox" @click="mandirect(man.id)">
          <ManPfp :man="man" />
          <div class="manname">{{man.name}}</div>
          <div style="margin-bottom: 8px;"><small>(#{{man.id}})</small></div>
          <div><strong>Manpower:</strong> {{man.manpower}}</div>
          <div><strong>Fights:</strong> {{man.fights}}</div>
          <div><strong>Wins:</strong> {{man.wins}} ({{ man.fights > 0 ? ((man.wins/man.fights)*100.0).toFixed(1) : "0" }}%)</div>
        </div>
    </div>


    <!-- TODO Loading your men, you have no men! -->
  </div>
  </div>
</template>

<script>
import faunadb from 'faunadb';
const q = faunadb.query
import {manStore} from "@/store";
import ManPfp from "@/components/ManPfp";
import {ImmutableXClient} from "@imtbl/imx-sdk";

export default {
  name: "ManList",
  components: {ManPfp},
  props: ['address', 'db'],
  computed:{
    men(){
      return manStore.state.menArray
    }
  },
  data(){
    return {
      loading: true,
      sorter: "id",
      qualityFilter: "All"
    }
  },
  methods:{
    mandirect(id){
      this.$router.push("/myman/"+id)
    },
    mansort(key){
      manStore.sortMenArray(key)
    },
    reverse(){
      manStore.reverseMenArray()
    },
    reset(){
      manStore.resetMenArray()
    },
    filterByQuality(){
      this.reset()
      if(this.qualityFilter !== "All"){
        manStore.filterMenArrayByQuality(this.qualityFilter)
      }
    },
    async getMen(){
      const self = this
      const client = await ImmutableXClient.build({publicApiUrl: 'https://api.x.immutable.com/v1'});
      let menArray = []
      let assetRequest
      let assetCursor

      do {
        try{
          assetRequest = await client.getAssets({
            user: self.address,
            status: 'imx',
            collection: '0xacb3c6a43d15b907e8433077b6d38ae40936fe2c',
            name: "A Real Man",
            cursor: assetCursor
          })
        } catch (err) {
          console.error(err)
          self.$emit('toast', "Error getting IMX man list.")
        }
        menArray = menArray.concat(assetRequest.result)
        assetCursor = assetRequest.cursor
      } while (assetCursor)

      await menArray.forEach(man => {
        manStore.updateMan(man.token_id, man.metadata)
      })

      await Object.keys(manStore.state.men).forEach(manId =>{
        self.db.query(
            q.Get(
                q.Match(q.Index('man_by_id'), manId)
            )
        )
        .then((ret) => {
          manStore.updateMan(manId, ret.data)
        })
        .catch(async (err) => {
          console.error(err)
          self.$emit('toast', "Error getting RMFC man list.")
        })
      })
      manStore.confirmChecked()
      self.loading = false
    }
  },
  mounted(){
    if(!this.address){
      this.$router.replace("/")
    }
    if(!manStore.state.checked){
      this.getMen()
    } else {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 180px;
}

#manlist{
  margin-top: 80px;
  padding:8px;
}

#manlisttitle{
  line-height: 3em;
}

.manbox{
  margin: 12px;
  max-width: 200px;
  text-align: center;
  cursor: pointer;
}

.manname{
  font-size: 130%;
}

@media screen and (min-width: 620px) {
  #manlisttitle{
    font-size: 48pt;
  }
}

#filterbar{
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
}
</style>