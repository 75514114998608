<template>
    <div class="footer">
      <div>
        Powered by <br>
        <a href="https://godsunchained.com/" target="_blank"><img src="/img/logo--gods-unchained-icon.webp"  class="minilogo"/></a>&nbsp;
        <a href="https://www.immutable.com/" target="_blank"><img src="/img/immutable.svg"  class="minilogo"/></a>&nbsp;
        <a href="https://metamask.io/" target="_blank"><img src="/img/metamask-fox.svg" class="minilogo"/></a>&nbsp;
      </div>
      <div>
        <router-link to="/leaderboard" style="font-size: 1.3em">View leaderboard</router-link>
        <router-link to="/about" style="font-size: 1.1em; margin-top:4px;display:block;">About Us/Links</router-link>
      </div>
      <div>
        Made by<br>
        <strong style="color:var(--man1)">codeword.eth</strong><br>
        (donations welcome)
      </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
.minilogo{
  max-width: 24px;
}
.footer{
  background: linear-gradient(180deg, rgba(8,8,8,0) 0%, rgba(8,8,8,1) 15%, rgba(8,8,8,1) 100%);
  text-align: center;
  padding: 32px 8px 18px;
  font-size: 0.65em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  position: fixed;
  align-items: center;
  bottom:0;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
}
</style>