<template>


  <div id="headerbg">
    <div id="landingcenter">
    <h1 id="landingtitle">Real Man<br>Fight Club</h1>
    <div id="landingsubtitle">A Gods Unchained Minigame</div>
    <div v-if="address"><router-link to="/men" class="btn landing-btn" @click="$emit('play');">Click here to view your Real Man army!</router-link></div>
    <div v-if="!address"><button type="button" class="btn landing-btn" @click="$emit('login')">Click to connect with MetaMask</button></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Landing",
  props: ['address'],
  emits: ['login', 'play']
}
</script>

<style scoped>
#headerbg{
  background-image: url("/img/big-home-bg.jpeg");
  background-size: cover;
  background-position: left top;
  width:100%;
  height:100vh;
  box-sizing: border-box;
  margin-top: 58px;
  overflow: hidden;
  position: fixed;
}

#landingcenter{
  box-sizing: border-box;
  width: 100%;
  padding: 0 36px;
  height: auto;
  text-align: center;
  max-width: 820px;
  margin: 15vh auto;
}

#landingtitle{
  font-size: 36pt;
  line-height: 40pt;
  text-shadow: 0 0 5px #000, 0 0 10px #000, 0 0 20px #000;
  display: block;
  margin: 0 0 36px;
}

@media screen and (max-width: 340px) {
  #landingtitle{
    font-size: 24pt;
    line-height: 28pt;
  }
}

@media screen and (min-width: 720px) {
  #landingcenter{
    top: 25vh;
  }
  #landingtitle{
    font-size: 64pt;
    line-height: 68pt;
  }
}


#landingsubtitle{
  text-shadow: 0 0 5px #000;
  font-weight: bold;
  font-style: italic;
  font-size: 1.1em;
  margin: 0 0 36px;
}

.landing-btn{
  display: block;
  margin: 18px auto;
  padding: 18px 12px;
  font-weight: bold;
  max-width: 400px;
  background: var(--man3);
  color: var(--text);
  font-size: 12pt !important;
}
.landing-btn:hover{
  background: var(--man1);
}
</style>